.category {
  padding-bottom: 30px;

  &__projects {

  }

  &__description {
    margin-top: 30px;
  }

  &__categories-title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__categories {
    display: flex;
    margin: 0 -15px;
    margin-bottom: 80px;
    flex-wrap: wrap;
  }

  &__categories-item {
    padding: 0 15px;
    flex: 0 0 50%;
    margin-bottom: 30px;
  }

  &__categories-info {
    padding: 50px 40px;
    border: 30px solid #f1f2f2;
    border-top: none;
    display: block;
  }

  &__categories-name {
    font-weight: 500;
    font-size: 30px;
    display: block;
    margin-bottom: 17px;
  }

  &__categories-img {
    object-fit: cover;
    width: 100%;
    display: block;
    height: 400px;
  }

  &__categories-link {
    color: $primary-color;
    box-shadow: inset 0 0 0 2px $primary-color;
    background-color: transparent;

    font-size: 17px;
    padding: 15px 80px;
    display: block;
    transition: all 0.4s;

    &:hover {

      background-color: $primary-color;
      color: #fff;
    }
  }

  &__projects-title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
  }


  &__filter-form {
    display: flex;

    .form-field {
      &>label {
        font-size: 15px;
        margin-bottom: 7px;
      }

      .checkbox-list {
        display: flex;

        input {
          display: none;
        }

        label {
          margin: 0;
          color: #000;
          background-color: #fff;
          font-size: 15px;
          padding: 10px 15px;
          border: 1px solid #edeeee;
          border-right: none;
          cursor: pointer;
          transition: all 0.4s;
        }

        input:checked + label {
          background-color: $primary-color;
          color: #fff;
        }

        li:last-child {
          border-right: 1px solid #edeeee;
        }
      }
    }
  }
}

@include respond-up('large') {
  .category {
    &__filter-form {
      justify-content: space-between;
      margin-bottom: 40px;
    }
  }
}

@include respond-up('medium') {
  .category {
    &__filter-form {
      flex-wrap: wrap;
      margin-bottom: 30px;

      .form-field {
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }

    &__filter-opener {
      display: none;
    }
  }
}

@include respond('medium') {
  .category {
    &__categories-title, &__projects-title {
      font-size: 25px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    &__categories {
      margin-bottom: 50px;
    }

    &__categories-img {
      height: 280px;
    }

    &__categories-name {
      font-size: 23px;
      margin-bottom: 13px;
    }

    &__categories-info {
      padding: 40px 30px;
      border: 20px solid #f1f2f2;
      border-top: none;
      display: block;
    }

    &__categories-button {
      font-size: 14px;
    }
  }
}

@include respond-down('small') {
  .category {
    &__categories-title, &__projects-title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    &__categories {
      margin: 0 0 40px;
      display: block;
    }

    &__categories-item {
      padding: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__categories-link {
      font-size: 15px;
      padding: 15px 30px;
      display: block;
      width: 100%;
      text-align: center;
    }

    &__categories-img {
      height: 220px;
    }

    &__categories-name {
      font-size: 18px;
      margin-bottom: 10px;
    }

    &__categories-info {
      padding: 30px 20px;
      border: 15px solid #f1f2f2;
      border-top: none;
      display: block;
    }

    &__categories-button {
      font-size: 13px;
    }

    &__filter-form {
      flex-wrap: wrap;
      display: none;

      .form-field {
        flex: 0 0 100%;

        &>label {
          font-size: 12px;
        }

        .checkbox-list {
          flex-wrap: wrap;

          label {
            font-size: 12px;
            border-top: none;
            padding: 8px 10px;
          }

          li {
            flex: 0 0 50%;


            &:nth-child(1), &:nth-child(2) {
              label {
                border-top: 1px solid #edeeee;
              }
            }
          }
        }
      }
    }

    &__filter {
      &.opened {
        .category {
          &__filter-form {
            display: block;
          }

          &__filter-opener-text {
            &_closed {
              display: none;
            }

            &_opened {
              display: inline;
            }
          }
        }
      }
    }

    &__filter-opener {
      color: #fff;
      background-color: $primary-color;
      font-size: 14px;
      padding: 10px;
      width: 100%;
      text-align: center;
      display: block;
      margin-bottom: 15px;
    }

    &__filter-opener-text {
      &_opened {
        display: none;
      }
    }
  }
}