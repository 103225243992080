.article {
  font-size: 15px;
  color: #363333;
  line-height: 1.6;

  table {
    width: 100%;

    tr {
      &:nth-child(even) {
        td, th {
          background-color: #F6F7F7;
        }
      }
    }

    td, th {
      padding: 11px 20px;
    }
  }

  &, p, li {
    font-size: 15px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ol {
    list-style: decimal;
    list-style-position: inside;
  }

  ul {
    padding-left: 15px;

    li {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: -15px;
        top: 5px;
        width: 6px;
        height: 6px;
        background-color: $primary-color;
      }
    }
  }

  blockquote {
    background-color: $bg-color;
    padding: 20px 20px 20px 40px;
    border-left: 2px solid #a79f9f;
  }
}

@include respond-down('small') {
  .article {
    &, p, li {
      font-size: 14px;
    }

    ul li:after {
      top: 7px;
    }

    blockquote {
      padding: 15px 15px 15px 30px;
    }
  }
}