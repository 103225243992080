.title {
  font-size: 44px;
  font-weight: 500;
}

@include respond('notebook') {
  .title {
    font-size: 38px;
  }
}

@include respond('medium') {
  .title {
    font-size: 35px;
  }
}

@include respond-down('small') {
  .title {
    font-size: 28px;
  }
}