.layout {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;
}

.layout__main {
  &_dark {
    background-color: $bg-color;
  }
}

@include respond-up('medium') {
  .layout {
    &__wrapper {
      padding-top: 90px;
    }
  }
}

@include respond-down('small') {
  .layout {
    &__wrapper {
      padding-top: 60px;
    }
  }
}