.recall {
  &__fields {

  }

  &__field {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    input[type=text] {
      height: 50px;
      font-size: 14px;
      padding-left: 20px;
    }

    &_button {

    }
  }

  &__button {
    width: 100%;
    height: 50px;
  }

  &__errors {

  }

  &__action {

  }

  &__policy {
    font-size: 13px;
  }

  &__policy-link {
    color: $primary-text-color;
  }

  &_column {
    .recall {
      &__field {
        &_button {
          display: none;
        }
      }

      &__action {
        margin-bottom: 20px;
      }
    }
  }

  &_line {
    .recall {
      &__fields {
        display: flex;
      }

      &__field {
        flex: 1 1 auto;

        &_input {

        }

        &_button {
          min-width: 200px;
        }
      }

      &__action {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .recall {
    &__policy {
      font-size: 11px;
    }

    &__button {
      height: 45px;
      font-size: 15px;
    }

    &__field {
      input[type=text] {
        height: 45px;
        font-size: 15px;
        padding-left: 15px;
      }
    }
  }
}

@include respond-down('small') {
  .recall {
    &__button {
      width: 100%;
      height: 40px;
      font-size: 14px;
    }

    &__field {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      input[type=text] {
        height: 40px;
        font-size: 14px;
        padding-left: 15px;
      }
    }

    &__policy {
      font-size: 11px;
    }

    &_line {
      .recall {
        &__field {
          &_button {
            display: none;
          }
        }

        &__action {
          margin-bottom: 20px;
        }
      }

      .recall {
        &__fields {
          display: block;
        }

        &__action {
          display: block;
        }
      }
    }
  }
}