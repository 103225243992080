.badge-link {
  position: fixed;
  top: 50%;
  margin-top: -30px;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px;
  padding-right: 20px;
  background-color: #137409;
  z-index: 1000;

  &__icon {
    width: 25px;
    height: 25px;
    background: {
      position: 50% 50%;
      repeat: no-repeat;
      size: contain;
    };

    &_houses {
      background-image: url("../images/svg/small-house.svg");
    }

    &_tools {
      background-image: url("..//images/svg/small-tools.svg");
    }
  }

  &__text {
    color: #fff;
    font-size: 14px;
    text-align: left;
    padding-left: 15px;
    line-height: 0.95;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 100%;
    border-top: 20px solid #137409;
    border-left: 14px solid transparent;
    border-bottom: 20px solid #137409;
  }
}

@media (max-width: 767px) {
  .badge-link {
    left: 0;
    right: auto;
    bottom: 18px;
    top: auto;
    padding-right: 15px;
    height: 50px;
    border-radius: 0 5px 5px 0;

    &__text {
      font-size: 14px;
    }
  }
}