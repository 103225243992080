.project-badge {
  background-color: #D62129;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  z-index: 100;
  color: #fff;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border-top: 15px solid #932127;
    border-right: 15px solid transparent;
  }
}

@include respond-down('small') {
  .project-badge {
    padding: 4px 10px 4px 14px;
    font-size: 12px;
  }
}