.review {
  padding: 30px 30px 30px 50px;
  background-color: #fff;

  &__name {
    font-size: 18px;
    margin-bottom: 10px;
  }

  &__content {
    display: flex;
  }

  &__text {
    font-size: 15px;
    flex: 1 1 auto;
    margin-right: 50px;
    line-height: (24/15);
    font-weight: 300;
  }

  &__image {
    flex: 0 0 182px;
  }

  &__img {
    border: 7px solid $bg-color;
  }
}

@include respond('medium') {
  .review {
    padding: 30px 20px 30px 40px;

    &__name {
      font-size: 16px;
    }

    &__text {
      font-size: 14px;
    }
  }
}

@include respond-down('small') {
  .review {
    padding: 20px 20px 20px 20px;

    &__name {
      font-size: 16px;
    }

    &__text {
      font-size: 13px;
      margin: 0;
    }

    &__content {
      display: block;
    }

    &__image {
      display: block;
      margin-top: 10px;
      max-width: 180px;
    }
  }
}