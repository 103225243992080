.index-services {
  padding: 120px 0 60px;
  box-shadow: inset 0 -400px 400px -400px rgba(0,0,0,0.1);

  &__title {
    text-align: center;
    font-weight: 500;
    margin-bottom: 60px;
  }
}

@include respond('notebook') {
  .index-services {
    padding: 60px 0 30px;

    &__title {
      margin-bottom: 30px;
    }
  }
}

@include respond('medium') {
  .index-services {
    padding: 40px 0 30px;

    &__title {
      margin-bottom: 20px;
    }
  }
}
@include respond-down('small') {
  .index-services {
    padding: 30px 0 30px;

    &__title {
      margin-bottom: 15px;
    }
  }
}
