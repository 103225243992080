.pages {
  &__list {

  }

  &__item {

  }

  &__item-compact {

  }

  &__more {
    height: 100%;
    border: 1px solid #B4B4B4;
    border-radius: 6px;
  }

  &__more-list {
    font-size: 18px;
    color: #081503;
  }

  &__more-item {
    padding-left: 20px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '–';
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__more-item-link {
    display: block;
    padding-bottom: 7px;

    &:hover {
      .pages {
        &__more-item-text {
          border-bottom: 1px solid $primary-color;
        }
      }
    }
  }

  &__more-item-text {
    border-bottom: 1px solid transparent;
    transition: all 0.3s;
  }

  &__more-link {
    font-weight: 500;
    font-size: 16px;
    color: $primary-color;
  }
}

@include respond-up('large') {
  .pages {
    &__list {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-gap: 30px;
    }
  }
}

@include respond-up('medium') {
  .pages {
    &__more {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 33px 40px 30px;
    }

    &__more-list {
      margin-bottom: 12px;
    }
  }
}

@include respond('medium') {
  .pages {
    &__item {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include respond-down('small') {
  .pages {
    &__item {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__more {
      padding: 30px 25px 30px;
    }

    &__more-list {
      margin-bottom: 20px;
    }
  }
}