.button {
  padding: 0;
  margin: 0;
  text-align: center;
  transition: $default-transition;
  display: inline-block;
  border: none;
  background: $primary-color;
  color: #fff;
  font-size: 17px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;

  &_expand, &_wide {
    width: 100%;
  }

  &_round {
    border-radius: 100px;
  }

  &_secondary {
    background-color: $secondary-color;
  }

  &_default {
    padding: 10px 30px;
  }

  &_transparent {
    border: 1px solid $primary-color;
    color: $primary-color;
    background-color: $t;
  }

  &_edit {
    padding: 10px 30px;
    border: 2px solid $primary-color;
    color: $primary-color;
    background-color: $t;
  }

  &_edit-float {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #fff;
    z-index: 100;
  }
}

@include respond-down('medium') {
  .button {
    &_edit-float {
      display: none;
    }
  }
}