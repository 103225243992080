.estimate {
  &__step-name {
    font-size: 18px;
    font-weight: 500;
    padding: 13px 23px;
    background-color: rgba(54, 51, 51, 0.09);
  }

  &__total {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    padding: 13px 23px;
  }

  &__total-label {
    flex: 0 0 500px;
    width: 500px;
  }

  &__item {
    display: flex;
    padding: 13px 23px;

    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      padding-bottom: 20px;
    }

    &:nth-child(even) {
      background-color: #F6F7F7;
    }
  }

  &__item-label {
    flex: 0 0 500px;
    width: 500px;
  }

  &__item-value {
    flex: 1 1 auto;
  }

  &__result {
    display: flex;
    justify-content: space-between;
    padding: 13px 23px;
    //background-color: #ffebed;
    font-size: 20px;
    font-weight: bold;
  }

  &__back-link {
    color: $primary-color;
    text-align: center;
    display: block;
    margin-top: 20px;
    margin-bottom: 30px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__push {
    height: 450px;
  }
}

@include respond-up('medium') {
  .estimate {
    &__item-value {
      text-align: right;
    }

    &__total-value {
      flex: 1 1 auto;
      text-align: right;
    }

    &__result {
      border-top: 2px solid rgba(54, 51, 51, 0.15);
    }
  }
}

@include respond-down('small') {
  .estimate {
    &__item {
      display: block;
    }

    &__item-label {
      width: auto;
      font-weight: 500;
    }

    &__total {
      display: block;
      font-weight: bold;
    }

    &__total-label {
      width: auto;
    }

    &__result {
      border-top: 2px solid rgba(54, 51, 51, 0.15);
      display: block;
    }

    &__result-label {
      margin-bottom: 10px;
    }
  }
}