.contacts-page {
  &__container {

  }

  &__requisites-title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__requisites {
    padding: 50px 0 70px;
  }
}

@include respond('notebook') {
  .contacts-page {
    &__requisites-title {
      font-size: 28px;
      margin-bottom: 20px;
    }

    &__requisites {
      padding: 40px 0 60px;
    }
  }
}

@include respond('medium') {
  .contacts-page {
    &__requisites-title {
      font-size: 25px;
      margin-bottom: 18px;
    }

    &__requisites {
      padding: 35px 0 55px;
    }
  }
}

@include respond-down('small') {
  .contacts-page {
    &__requisites-title {
      font-size: 20px;
      margin-bottom: 15px;
    }

    &__requisites {
      padding: 25px 0 45px;
    }
  }
}