.footer {
  padding: 54px 0 110px;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__meta {
    flex: 0 0 270px;
    width: 270px;
    font-size: 13px;
    margin-right: 30px;
  }

  &__logo {
    margin-bottom: 30px;
  }

  &__copy {
    margin-bottom: 8px;
    display: block;
  }

  &__policy {
    margin-bottom: 8px;
    display: block;
  }

  &__author {
    display: block;
  }

  &__contacts {
    flex: 1 1 auto;
  }

  &__phone {
    font-size: 18px;
    font-weight: 500;
    display: block;
    margin-bottom: 20px;
  }

  &__address {
    display: block;
    margin-bottom: 20px;
  }

  &__email {
    display: block;
    margin-bottom: 20px;
  }

  &__socials {
    display: flex;
    align-items: flex-start;
  }

  &__social-link {
    opacity: 0.5;
    transition: all 0.4s;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__menu {
    width: 320px;
    flex: 0 0 320px;
    justify-self: flex-end;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      background-color: $bg-color;
      width: 30px;
      left: -100px;
      top: 0;
      height: 100px;
    }
  }

  &__menu-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }

  &__menu-item {
    width: 50%;
    flex: 0 0 50%;
    margin-bottom: 20px;
  }
}

@include respond('medium') {
  .footer {
    padding: 25px 0;

    &__meta {
      order: 3;
      width: 160px;
      flex: 0 0 160px;
      margin: 0;
    }

    &__menu {
      flex: 1 1 auto;
      width: auto;
      padding-right: 30px;
      max-width: 400px;

      &:before {
        display: none;
      }
    }

    &__contacts {
      flex: 1 1 auto;
      width: auto;
      padding-right: 30px;
      max-width: 300px;
    }

    &__reserved {
      display: block;
    }
  }
}

@include respond-down('small') {
  .footer {
    padding: 25px 0;

    &__inner {
      display: flex;
      flex-direction: column;
    }

    &__meta {
      order: 3;
      width: auto;
      flex: 1 1 auto;
    }

    &__menu {
      flex: 1 1 auto;
      width: auto;
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid $bg-color;
    }

    &__contacts {
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid $bg-color;
    }
  }
}