.breadcrumbs {
  font-size: 13px;

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    &_delimiter {
      padding: 0 4px;
    }
  }

  &__link {

  }
}

@include respond('medium') {
  .breadcrumbs {
    font-size: 12px;
  }
}

@include respond-down('small') {
  .breadcrumbs {
    font-size: 11px;
  }
}