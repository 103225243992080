.work {
  &__container {

  }

  &__main {
    display: flex;
    padding-bottom: 60px;
  }

  &__images {
    flex: 0 0 815px;
    margin-right: 55px;
  }

  &__image {

  }

  &__options {
    flex: 1 1 auto;
  }

  &__dimensions {
    margin-bottom: 18px;
  }

  &__term {
    margin-bottom: 22px;
  }

  &__term-value {
    font-weight: 500;
  }

  &__short-description {
    margin-bottom: 40px;
  }

  &__action {

  }

  &__button {
    height: 50px;
    line-height: 50px;
    text-align: center;
    width: 100%;
  }

  &__steps {
    padding: 45px 0 55px;
    background-color: $bg-color;
  }

  &__subtitle {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 39px;
  }

  &__steps-title {

  }

  &__steps-list {

  }

  &__steps-list-item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(2n + 2) {
      .work {
        &__step {
          flex-direction: row-reverse;
        }
      }
    }
  }

  &__step {
    display: flex;
  }

  &__step-image {
    flex: 0 0 815px;
    display: block;
  }

  &__step-img {
    height: 510px;
    width: 810px;
    object-fit: cover;
    display: block;
  }

  &__step-text {
    align-self: flex-end;
    background-color: #fff;
    padding: 75px 35px 75px 55px;
    font-size: 15px;
    font-weight: 300;
    line-height: (24/15);
  }

  &__video {
    padding: 45px 0 55px;
  }

  &__video-title {

  }

  &__video-link {
    display: block;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
      transition: all 0.4s;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      background-image: url('../svg/play.svg');
      z-index: 10;
      width: 172px;
      height: 171px;
      margin-top: -75px;
      margin-left: -76px;
      transition: all 0.4s;
      background-repeat: no-repeat;
    }

    &:hover {
      &:after {
        transform: scale(1.1);
      }

      &:before {
        background-color: rgba(0,0,0,0.4);
      }
    }
  }

  &__video-img {
    width: 100%;
    height: 510px;
    object-fit: cover;
    display: block;
  }

  &__project {
    padding: 45px 0 55px;
    background: $bg-color;
  }

  &__project-title {

  }

  &__similar-form {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0 80px;
  }

  &__similar-form-container {
    flex: 0 0 100%;
    padding: 40px 0;
  }

  &__similar-form-title {
    font-size: 36px;
    font-weight: 500;
    color: $text-color;
    margin-bottom: 30px;
  }

  &__similar-form-subtitle {
    color: $text-color;
    margin-bottom: 15px;
  }

  &__details {
    padding: 45px 0 55px;
  }

  &__details-title {

  }

  &__details-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  &__details-list-item {
    padding: 0 15px;
    margin-bottom: 30px;

    &:nth-child(4n + 1), &:nth-child(4n + 4) {
      flex: 0 0 41%;
    }

    &:nth-child(4n + 2), &:nth-child(4n + 3) {
      flex: 0 0 59%;
    }
  }

  &__detail {
    display: block;
    position: relative;
    overflow: hidden;

    &:hover {
      .work {
        &__detail-text {
          opacity: 1;
          transform: translate3d(0,0,0);
        }
      }
    }
  }

  &__detail-text {
    position: absolute;
    right: 0;
    bottom: 0;
    background: $bg-color;
    padding: 25px;
    font-size: 15px;
    line-height: (20/15);
    max-width: 50%;
    opacity: 0;
    transform: translate3d(100%,0,0);
    transition: all 0.6s;
  }

  &__detail-img {
    display: block;
    height: 315px;
    width: 100%;
    object-fit: cover;
  }

  &__about {
    background-color: $bg-color;
    padding: 75px 0 35px;
  }

  &__about-title {

  }

  &__about-container {
    display: flex;
  }

  &__about-text {

  }

  &__about-form {
    flex: 0 0 440px;
    width: 440px;
    margin-left: 68px;
    background-color: #fff;
    padding: 80px 40px 80px 50px;

    align-self: flex-start;
  }

  &__about-form-title {
    font-size: 36px;
    font-weight: 500;
    color: $text-color;
    margin-bottom: 30px;
    line-height: 1;
  }

  &__about-form-subtitle {
    color: $text-color;
    margin-bottom: 20px;
  }

  &__similar {
    padding: 50px 0;
  }

  &__similar-title {

  }

  &__similar-list {

  }
}

@include respond('notebook') {
  .work {
    &__images {
      width: 730px;
      flex: 0 0 730px;
    }

    &__detail-text {
      opacity: 1;
      transform: translate3d(0,0,0);
    }
  }
}

@include respond('medium') {
  .work {
    &__images {
      width: calc(100% - 320px);
      flex: 0 0 calc(100% - 320px);
    }

    &__subtitle {
      font-size: 30px;
      margin-bottom: 25px;
    }

    &__options {
      font-size: 14px;
    }

    &__term {
      margin-bottom: 10px;
    }

    &__term-value {
      font-weight: 500;
    }

    &__short-description {
      margin-bottom: 20px;
    }

    &__button {
      height: 45px;
      line-height: 45px;
      font-size: 16px;
    }

    &__steps {
      padding: 35px 0 35px;
    }

    &__step {
      display: block;
      position: relative;
    }

    &__step-text {
      padding: 40px;
      width: 400px;
      position: absolute;
      bottom: -50px;
    }

    &__step-img {
      height: 420px;
      width: 100%;
      object-fit: cover;
      display: block;
    }

    &__steps-list-item {
      margin-bottom: 80px;

      &:last-child {
        margin-bottom: 50px;
      }

      &:nth-child(2n + 1) {
        .work {
          &__step {
            padding-right: 50px;
          }

          &__step-text {
            right: 0;
          }
        }
      }

      &:nth-child(2n + 2) {
        .work {
          &__step {
            padding-left: 50px;
          }

          &__step-text {
            left: 0;
          }
        }
      }
    }

    &__video-img {
      width: 100%;
      height: 420px;
      object-fit: cover;
      display: block;
    }

    &__video {
      padding: 35px 0 25px;
    }


    &__similar-title {
      margin-bottom: 40px;
    }

    &__project {
      background-color: $bg-color;
      padding: 40px 0 20px;
    }

    &__similar-form {
      padding: 0 50px;
    }

    &__similar-form-container {
      padding: 20px 0;
    }

    &__similar-form-title {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    &__similar-form-subtitle {
      margin-bottom: 15px;
    }

    &__details-list {
      display: block;
    }

    &__details-list-item {

    }

    &__detail {
      overflow: visible;
    }

    &__detail-text {
      padding: 40px;
      width: 400px;
      position: absolute;
      bottom: -50px;
      opacity: 1;
      transform: translate3d(0,0,0);
    }

    &__detail-img {
      height: 420px;
      width: 100%;
      object-fit: cover;
      display: block;
    }

    &__details-list-item {
      margin-bottom: 80px;

      &:last-child {
        margin-bottom: 50px;
      }

      &:nth-child(2n + 1) {
        .work {
          &__detail {
            padding-right: 50px;
          }

          &__detail-text {
            right: 0;
          }
        }
      }

      &:nth-child(2n + 2) {
        .work {
          &__detail {
            padding-left: 50px;
          }

          &__detail-text {
            left: 0;
          }
        }
      }
    }

    &__about {
      background-color: $bg-color;
      padding: 45px 0 35px;
    }

    &__about-title {
      margin-bottom: 30px;
    }

    &__about-form {
      flex: 0 0 300px;
      width: 300px;
      margin-left: 38px;
      padding: 40px 30px 40px 30px;
    }

    &__about-form-title {
      font-size: 28px;
      margin-bottom: 20px;
    }

    &__about-form-subtitle {
      margin-bottom: 15px;
    }
  }
}

@include respond-down('small') {
  .work {
    &__main {
      display: block;
      padding-bottom: 30px;
    }

    &__images, &__options {
      width: 100%;
    }

    &__images {
      margin-bottom: 10px;
    }

    &__subtitle {
      font-size: 22px;
      margin-bottom: 20px;
    }

    &__options {
      font-size: 14px;
    }

    &__term {
      margin-bottom: 10px;
    }

    &__short-description {
      margin-bottom: 20px;
    }

    &__button {
      height: 45px;
      line-height: 45px;
      font-size: 16px;
    }

    &__steps {
      padding: 30px 0;
    }

    &__step {
      display: block;
      position: relative;
    }

    &__step-text {
      padding: 20px;
      font-size: 14px;
      width: auto;
      position: relative;
      z-index: 10;
    }

    &__step-img {
      height: 230px;
      width: 100%;
      object-fit: cover;
      display: block;
    }

    &__steps-list-item {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(2n + 1) {
        .work {
          &__step-image {
            padding-right: 20px;
          }

          &__step-text {
            margin-left: 20px;
            margin-top: -20px;
          }
        }
      }

      &:nth-child(2n + 2) {
        .work {
          &__step-image {
            padding-left: 20px;
          }

          &__step-text {
            margin-right: 20px;
            margin-top: -20px;
          }
        }
      }
    }

    &__details-list {
      display: block;
    }

    &__detail {
      display: block;
      position: relative;
    }

    &__detail-image {
      display: block;
    }

    &__detail-text {
      padding: 20px;
      font-size: 14px;
      width: auto;
      position: relative;
      z-index: 10;
      opacity: 1;
      transform: translate3d(0,0,0);
      display: block;
      max-width: none;
    }

    &__detail-img {
      height: 230px;
      width: 100%;
      object-fit: cover;
      display: block;
    }

    &__details-list-item {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(2n + 1) {
        .work {
          &__detail-image {
            padding-right: 20px;
          }

          &__detail-text {
            margin-left: 20px;
            margin-top: -20px;
          }
        }
      }

      &:nth-child(2n + 2) {
        .work {
          &__detail-image {
            padding-left: 20px;
          }

          &__detail-text {
            margin-right: 20px;
            margin-top: -20px;
          }
        }
      }
    }

    &__video-img {
      width: 100%;
      height: 230px;
      object-fit: cover;
      display: block;
    }

    &__video {
      padding: 35px 0 25px;
    }

    &__video-link {
      &::after {
        width: 100px;
        height: 100px;
        margin-top: -50px;
        margin-left: -50px;
        background-size: contain;
        background-repeat: repeat;
        background-position: 50% 50%;
      }
    }

    &__project {
      padding: 30px 0 0;
    }

    &__similar-form {
      padding: 0 20px;
    }

    &__similar-form-container {
      padding: 25px 0;
    }

    &__similar-form-title {
      font-size: 22px;
      margin-bottom: 10px;
    }

    &__similar-form-subtitle {
      margin-bottom: 15px;
      font-size: 14px;
    }

    &__about {
      background-color: $bg-color;
      padding: 30px 0 15px;
    }

    &__about-container {
      display: block;
    }

    &__about-title {
      margin-bottom: 15px;
    }

    &__about-form {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
      padding: 30px 25px 30px 25px;
    }

    &__about-form-title {
      font-size: 22px;
      margin-bottom: 10px;
    }

    &__about-form-subtitle {
      font-size: 14px;
      margin-bottom: 15px;
    }

    &__similar {
      padding: 30px 0;
    }
  }
}