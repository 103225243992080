.success {
  &__icon {
    margin-bottom: 30px;
  }

  &__title {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 1;
  }

  &__text {
    font-size: 17px;
  }
}