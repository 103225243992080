.latest-works {
  padding: 110px 0 130px;

  &__title {
    text-align: center;
    font-size: 44px;
    font-weight: 500;
    margin-bottom: 60px;
  }

  &__action {
    text-align: center;
  }

  &__button {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    font-weight: 400;
    width: 350px;
  }

  &__list {
    margin-bottom: 50px;
  }
}

@include respond('notebook') {
  .latest-works {
    padding: 60px 0 60px;

    &__title {
      font-size: 38px;
      margin-bottom: 40px;
    }

    &__list {
      margin-bottom: 20px;
    }
  }
}

@include respond('medium') {
  .latest-works {
    padding: 60px 0 60px;

    &__title {
      font-size: 35px;
      margin-bottom: 40px;
    }

    &__list {
      margin-bottom: 20px;
    }
  }
}

@include respond-down('small') {
  .latest-works {
    padding: 50px 0 50px;

    &__title {
      font-size: 28px;
      margin-bottom: 40px;
    }

    &__list {
      margin-bottom: 10px;
    }

    &__button {
      height: auto;
      line-height: 1.2;
      padding: 15px 50px;
      font-size: 14px;
      width: auto;
    }
  }
}