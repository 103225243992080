.reviews {
  &__list {
    margin-bottom: 50px;
  }

  &__list-item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__pager {

  }

  &__action {
    text-align: center;
  }

  &__button {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    font-weight: 400;
    width: 250px;
  }
}

@include respond('medium') {
  .reviews {
    &__list {
      margin-bottom: 20px;
    }

    &__list-item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__button {
      height: 45px;
      line-height: 45px;
      font-size: 14px;
      width: 200px;
    }
  }
}

@include respond('notebook') {
  .reviews {
    &__list {
      margin-bottom: 30px;
    }

    &__list-item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include respond-down('small') {
  .reviews {
    &__list {
      margin-bottom: 20px;
    }

    &__list-item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__button {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      width: 200px;
    }
  }
}