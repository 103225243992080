$max-row-width: 1200;
$grid-columns: 12;
$grid: (
  small: (
    from: 0,
    to: 767,
    gutter: 12,
    spacer: 15
  ),
  medium: (
    from: 768,
    to: 1149,
    gutter: 30,
    spacer: 20
  ),
  notebook: (
    from: 1150,
    to: 1379,
    gutter: 30,
    spacer: 20
  ),
  large: (
    from: 1380,
    gutter: 30
  )
);
$body-font-size: 16px;
$font: "Geometria", sans-serif;
$h-font: "Geometria", sans-serif;

$t: transparent;

$primary-color: #D62129;
$primary-text-color: #932127;
$secondary-color: #137409;
$alert-color: #b50000;
$text-color: #363333;
$bg-color: #F6F7F7;

$default-transition: all 0.4s;

$modal-content-padding: 40px;
$modal-content-radius: 0;