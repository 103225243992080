.index-about {
  padding: 100px 0 55px;

  &__title {
    text-align: center;
    font-size: 44px;
    font-weight: 500;
    margin-bottom: 75px;
  }
}

@include respond('medium') {
  .index-about {
    padding: 60px 0 40px;

    &__title {
      font-size: 35px;
      margin-bottom: 50px;
      line-height: 1;
    }
  }
}

@include respond-down('small') {
  .index-about {
    padding: 30px 0 30px;

    &__title {
      font-size: 28px;
      margin-bottom: 30px;
      line-height: 1;
    }
  }
}