.project-card {
  display: block;
  position: relative;

  &__image {
    display: block;
    overflow: hidden;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    display: block;
  }

  &__badge {
    top: 0;
    right: 0;
  }

  &__info {
    display: block;
    background-color: #fff;
  }

  &__data {

  }

  &__title {
    font-weight: 500;
    color: #363333;
    display: block;
    line-height: 1;
  }

  &__dimensions {
    display: flex;
    align-items: center;
    margin: 0 -9px;
  }

  &__dimension {
    padding: 0 9px;
    display: flex;
    align-items: center;
  }

  &__dimension-icon {
    margin-right: 9px;
    padding-bottom: 2px;
  }

  &__dimension-value {
    font-size: 14px;
    color: #363333;
  }

  &__description {
    display: block;
  }

  &__description-item {

  }

  &__price {
    display: block;
  }

  &__price-description {

  }

  &__price-value {

  }

  &__price-numbers {
    font-weight: 500;
    white-space: nowrap;
  }

  &__term {

  }

  &__term-value {
    font-weight: 500;
  }

  &__button {
    background-color: $secondary-color;
    font-size: 17px;
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    text-align: center;
    bottom: -20px;
    z-index: 120;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0,30px,0);
    transition: all 0.3s;
  }

  &:hover {
    .project-card {
      &__button {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0,0,0);
        transition: all 0.6s;
      }
    }
  }
}

@include respond-up('medium') {
  .project-card {
    &_small {
      .project-card {
        &__img {
          height: 240px;
        }

        &__info {
          padding: 40px 40px;
        }

        &__title {
          font-size: 30px;
          margin-bottom: 15px;
        }

        &__dimensions {
          margin-bottom: 20px;
        }

        &__price {
          display: block;
        }

        &__price-numbers {
          font-size: 18px;
          padding: 0 2px;
        }

        &__price-description {
          display: none;
        }

        &__description {
          display: none;
        }

        &__term {
          display: block;
          margin-top: 17px;
          font-size: 15px;
        }
      }
    }

    &_big {
      .project-card {
        &__img {
          height: 380px;
        }

        &__info {
          padding: 65px 50px;
        }

        &__title {
          font-size: 30px;
          margin-bottom: 15px;
        }

        &__dimensions {
          margin-bottom: 20px;
        }

        &__price-numbers {
          font-size: 20px;
        }

        &__price-description {
          display: block;
          font-size: 15px;
          color: #363333;
          margin-bottom: 4px;
        }

        &__term {
          display: block;
          margin-top: 10px;
          font-size: 15px;
        }

        &__description {
          display: none;
        }
      }
    }

    &_line {
      display: flex;

      .project-card {
        &__info {
          order: 1;
          flex: 1 1 auto;
          display: flex;
          align-items: center;
        }

        &__image {
          order: 2;
          flex: 0 0 54.2735%;
        }

        &__img {
          height: 430px;
        }

        &__info {
          padding: 65px 50px;
        }

        &__title {
          font-size: 30px;
          margin-bottom: 15px;
        }

        &__dimensions {
          margin-bottom: 15px;
        }

        &__price-numbers {
          font-size: 20px;
        }

        &__price-description {
          font-size: 15px;
          color: #363333;
          margin-bottom: 4px;
        }

        &__description {
          color: #363333;
          font-size: 15px;
          margin-bottom: 20px;
        }

        &__description-item {
          display: block;
        }

        &__button {
          width: 300px;
          left: 100px;
        }
      }
    }
  }
}

@include respond('medium') {
  .project-card {
    &__dimensions {
      flex-wrap: wrap;
      margin-bottom: 10px;
    }

    &__dimension-icon {
      margin-right: 7px;
      padding-bottom: 2px;
    }

    &__dimension-value {
      font-size: 13px;
    }

    &_small, &_big, &_line {
      .project-card {
        &__title {
          font-size: 20px;
          margin-bottom: 15px;
        }

        &__info {
          padding: 20px 20px;
        }

        &__dimensions {
          margin-bottom: 15px;
        }

        &__term {
          display: block;
          margin-top: 13px;
          font-size: 14px;
        }

        &__price-numbers {
          font-size: 16px;
          padding: 0 2px;
        }

        &__description {
          font-size: 14px;
        }

        &__price-value {
          display: block;
        }
      }
    }

    &_small {
      .project-card {
        &__img {
          height: 200px;
        }
      }
    }

    &_big {
      .project-card {
        &__img {
          height: 300px;
        }
      }
    }

    &_line {
      .project-card {
        &__price-numbers {
          font-size: 18px;
        }
      }
    }
  }
}

@include respond('notebook') {
  .project-card {
    &_small, &_big, &_line {
      .project-card {
        &__title {
          font-size: 25px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .project-card {
    &_small, &_big, &_line {
      .project-card {

        &__dimension-icon {
          margin-right: 7px;
          padding-bottom: 2px;
        }

        &__dimension-value {
          font-size: 13px;
        }

        &__img {
          height: 200px;
        }

        &__info {
          padding: 20px 20px;
        }

        &__title {
          font-size: 16px;
          margin-bottom: 10px;
        }

        &__dimensions {
          margin-bottom: 15px;
        }

        &__price {
          display: block;
        }

        &__price-numbers {
          font-size: 16px;
          padding: 0 2px;
        }

        &__price-description {
          display: none;
        }

        &__description {
          display: none;
        }

        &__term {
          display: block;
          margin-top: 13px;
          font-size: 14px;
        }
      }
    }
  }
}