.content-header {
  padding-top: 20px;
  padding-bottom: 44px;

  h1, .title {
    margin-top: 20px;
  }
}

@include respond('notebook') {
  .content-header {
    padding-top: 20px;
    padding-bottom: 30px;

    h1, .title {
      margin-top: 10px;
    }
  }
}

@include respond('medium') {
  .content-header {
    padding-top: 20px;
    padding-bottom: 30px;

    h1, .title {
      margin-top: 10px;
    }
  }
}

@include respond-down('small') {
  .content-header {
    padding-top: 15px;
    padding-bottom: 20px;

    h1, .title {
      margin-top: 10px;
    }
  }
}