.modal-form {
  max-width: 400px;

  &__title {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__description {
    font-size: 17px;
    margin-bottom: 25px;
  }

  &__fields {
    margin-bottom: 20px;

    label {
      display: none;
    }
  }

  &__button {
    height: 50px;
    line-height: 50px;
    width: 100%;
    margin-bottom: 10px;
  }

  &__policy {
    font-size: 12px;
    text-align: center;
  }

  &__policy-link {
    color: $primary-color;
    display: block;
  }
}

@include respond-down('small') {
  .modal-form {
    &__title {
      font-size: 24px;
      margin-bottom: 15px;
    }

    &__description {
      font-size: 14px;
      margin-bottom: 20px;
    }

    &__fields {
      margin-bottom: 10px;
    }

    input[type=text], &__button {
      height: 45px;
      line-height: 45px;
      font-size: 14px;
    }
  }
}