.article-category {

  padding-bottom: 60px;

  &__list {

  }

  &__pager {

  }
}

@include respond-down('small') {
  .article-category {
    padding-bottom: 30px;
  }
}