.simple-slider {
  &__item {
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
    transition: all 0.5s;

    &._active {
      opacity: 1;
      height: auto;
      width: auto;
      visibility: visible;
    }
  }
}