.step {
  margin-bottom: 30px;

  &__name {
    position: relative;

    &_center {
      text-align: center;
    }
  }

  &__name-text {
    font-weight: 500;
    position: relative;
    z-index: 10;
  }

  &__name-num {
    color: #fff;
    position: absolute;
    top: 0;
    left: -6px;
    font-weight: bold;
    line-height: 1;
    z-index: 1;
  }

  &__buttons {
    display: flex;
  }

  &__next, &__prev {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__prev + &__next {
    margin-bottom: 10px;
  }

  &__inputs {
    margin-bottom: 45px;
  }
}

@include respond-up('medium') {
  .step {
    &__name-text {
      font-size: 28px;
    }

    &__name-num {
      font-size: 70px;
      top: -12px;
    }

    &__name {
      margin-bottom: 28px;
    }

    &__next, &__prev {
      padding: 0 85px;
    }

    &__buttons {
      justify-content: space-between;
    }
  }
}
@include respond-down('small') {
  .step {
    &__name-text {
      font-size: 20px;
    }

    &__name-num {
      font-size: 45px;
      top: -7px;
      left: -3px;
    }

    &__name {
      margin-bottom: 28px;
    }

    &__buttons {
      flex-direction: column-reverse;
    }

    &__next, &__prev {
      width: 100%;
    }
  }
}