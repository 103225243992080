.page-card {
  display: block;
  position: relative;
  height: 100%;

  &__info {
    display: block;
    background-color: #F6F6F6;
    border-radius: 6px;
  }

  &__data {
    display: block;
  }

  &__name {
    font-weight: 500;
    display: block;
  }

  &__text {
    display: block;
    margin-bottom: 10px;
  }

  &__image {
    display: block;
    position: absolute;
    border-radius: 6px;
    overflow: hidden;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: {
      position: 50% 50%;
      repeat: no-repeat;
      size: cover;
    };
    transition: all 1s;
    transform: scale(1.05);
  }

  &__more {
    font-weight: 500;
    color: $primary-color;
    font-size: 16px;
    display: block;
  }

  &:hover {
    .page-card__img {
      transform: scale(1);
    }
  }
}

@include respond-up('medium') {
  .page-card {
    padding-right: 42px;

    &__info {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 47px 210px 30px 44px;
    }

    &__name {
      font-size: 22px;
      margin-bottom: 9px;
    }

    &__text {
      font-size: 18px;
      line-height: (28/18);
      height: 140px;
      overflow: hidden;
      position: relative;
    }

    &__image {
      right: 0;
      top: 15px;
      bottom: 5px;
      width: 215px;
    }
  }
}


@include respond-down('small') {
  .page-card {
    padding-top: 21px;

    &__info {
      padding: 55% 25px 25px;
    }

    &__name {
      font-size: 20px;
      margin-bottom: 10px;
      line-height: 1.4;
    }

    &__text {
      font-size: 18px;
      line-height: (28/18);
      margin-bottom: 25px;
    }

    &__image {
      top: 0;
      left: 10px;
      right: 10px;
      padding-top: (117/218)*100%;
    }
  }
}