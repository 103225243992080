.works-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  &__item {
    padding: 0 15px 30px;

    &_small {
      flex: 0 0 33.3333%;
    }

    &_big {
      flex: 0 0 50%;
    }

    &_form {
      flex: 0 0 66.6666%;
    }

    &_line {
      flex: 0 0 100%;
    }
  }
}

@include respond('medium') {
  .works-list {
    margin: 0 -10px;

    &__item {
      padding: 0 10px 20px;
    }
  }
}

@include respond-down('small') {
  .works-list {
    margin: 0 -8px;

    &__item {
      padding: 0 8px 16px;
      flex: 0 0 100%;
    }
  }
}