.about-page {
  &__container {

  }

  &__text {
    padding: 20px 0 40px;
  }
}

@include respond-down('small') {
  .about-page {
    &__text {
      padding: 0 0 20px;
    }
  }
}