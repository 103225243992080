.request-line {
  // TODO image
  background-color: #DAA054;
  background: {
    image: url("../images/forms/back.jpg");
    position: 50% 50%;
    repeat: no-repeat;
    size: cover;
  };
  padding: 40px 0;

  &__form {
    padding: 70px 75px;
    background-color: #fff;
    width: 745px;
  }

  &__form-title {
    line-height: 1;
    font-weight: 500;
    font-size: 40px;
    margin-bottom: 15px;
  }

  &__form-subtitle {
    font-size: 22px;
    line-height: (29/22);
    margin-bottom: 28px;
  }
}

@include respond("notebook") {
  .request-line {
    &__form {
      padding: 60px 60px;
      width: 725px;
    }
  }
}

@include respond("medium") {
  .request-line {
    &__form {
      padding: 40px 40px;
      width: 480px;
    }

    &__form-title {
      font-size: 30px;

      br {
        display: none;
      }
    }

    &__form-subtitle {
      font-size: 14px;
      margin-bottom: 20px;

      br {
        display: none;
      }
    }
  }
}

@include respond-down("small") {
  .request-line {
    padding: 0;

    .row, .column {
      padding: 0;
      margin: 0;
    }

    &__form {
      padding: 40px 40px;
      width: auto;
    }

    &__form-title {
      font-size: 25px;

      br {
        display: none;
      }
    }

    &__form-subtitle {
      font-size: 14px;
      margin-bottom: 20px;

      br {
        display: none;
      }
    }
  }
}