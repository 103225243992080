.calc {
  &__request {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(246, 246, 246, 0.95);

    &._show {
      display: flex;
      align-items: center;
    }
  }


  &__request-close {
    background: $primary-color url("../images/closer.svg") 0 0 no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 100px;
    cursor: pointer;
  }

  &__request-prevention {
    font-size: 12px;
    margin-top: 15px;
  }

  &__request-data {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__request-form {
    width: 300px;
  }

  &__request-or {
    padding: 0 35px;
  }

  &__request-show {
    width: 300px;
  }

  &__houses, &__kinds {
    margin-bottom: 40px;
  }

  &__request-close {
    top: -40px;
    right: -40px;
  }

  &__request-title {
    font-weight: 500;
  }

  &__push {
    padding-top: 50px;
  }

  &__container {
    position: relative;
  }

  &__request-button {
    width: 100%;
    height: 40px;
  }

  &__agreement {
    padding-top: 15px;
  }
}

@include respond-up('large') {
  .calc {
    &__data {
      max-width: 922px;
      margin: 0 auto;
    }
  }
}

@include respond-up('medium') {
  .calc {

    &__request {
      padding: 40px 0;
    }

    &__request-main {
      background-color: #fff;
      padding: 40px;
      max-width: 500px;
    }

    &__request-title {
      font-size: 28px;
      margin-bottom: 20px;
    }

    &__request-description {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
}

@include respond('medium') {
  .calc {
    &__request {
      &._show {
        display: block;
      }
    }

    &__request-data {
      max-width: 310px;
      position: sticky;
      top: 70px;
      margin: 0 auto;
    }

    &__request-or {
      padding: 25px 0;
    }

    &__socials {
      margin-top: 40px;
    }

    &__request-close {
      right: -50px;
    }
  }
}

@include respond-down('small') {
  .calc {
    &__request {
      padding: 20px 0;
    }

    &__request-main {
      background-color: #fff;
      padding: 20px;
      max-width: 500px;
    }

    &__request-title {
      font-size: 20px;
      margin-bottom: 20px;
    }

    &__request-description {
      font-size: 16px;
      margin-bottom: 20px;
    }

    &__request {
      &._show {
        display: block;
      }
    }

    &__request-data {
      max-width: 310px;
      position: sticky;
      top: 60px;
      margin: 0 auto;
    }

    &__request-description {
      padding-right: 30px;
    }

    &__houses, &__kinds {
      margin-bottom: 35px;
    }

    &__request-or {
      padding: 25px 0;
    }
    &__request-close {
      right: 0;
    }
  }
}
