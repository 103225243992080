.project {
  &__main {
    display: flex;
  }

  &__container {
  }

  &__images-form {
    width: 815px;
    flex: 0 0 815px;
  }

  &__images {
    position: relative;
  }

  &__badge {
    top: 20px;
    right: 38px;
  }

  &__images-list {
    .fotorama__thumb-border {
      border-color: $primary-color;
    }
  }

  &__form {
    border: 30px solid #F6F7F7;
    padding: 73px 88px 63px;
    margin-bottom: 50px;
  }

  &__form-title {
    font-weight: 500;
    font-size: 36px;
    line-height: 1;
    margin-bottom: 20px;
  }

  &__form-description {
    font-size: 18px;
    color: #363333;
    margin-bottom: 15px;
  }

  &__options {
    flex: 1 1 auto;
    padding-left: 58px;
  }

  &__price {
    margin-bottom: 27px
  }

  &__price-value {
    font-size: 32px;
    font-weight: 500;
  }

  &__price-description {
    font-size: 15px;
    color: #363333;
  }

  &__order {
    margin-bottom: 32px;
  }

  &__request-button {
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }

  &__dimensions {
    margin-bottom: 40px;
  }

  &__dimension {
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
    font-size: 15px;

    &:nth-child(odd) {
      background-color: #F6F7F7;
    }
  }

  &__dimension-name {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    font-weight: 500;
  }

  &__dimension-icon {
    flex: 0 0 28px;
    width: 28px;
    align-items: center;
    padding-bottom: 2px;
  }

  &__dimension-value {
    flex: 0 0 70px;
    width: 70px;
    display: flex;
    align-items: center;
  }

  &__characteristics {

  }

  &__characteristic {
    display: flex;
    margin-bottom: 50px;
  }

  &__characteristic-icon {
    width: 50px;
    flex: 0 0 50px;
  }

  &__characteristic-image {

  }

  &__characteristic-info {

  }

  &__characteristic-name {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 3px;
  }

  &__characteristic-value {
    font-size: 15px;
  }

  &__floors {
    margin-bottom: 80px;
  }

  &__subtitle {
    font-size: 36px;
    font-weight: 500;
  }

  &__floors-title {
    margin-bottom: 42px;
  }

  &__floors-items {
    display: flex;
    margin: 0 -15px;
  }

  &__floor {
    flex: 0 0 50%;
    width: 50%;
    padding: 0 15px;
  }

  &__floor-title {
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 14px;
  }

  &__floor-image {

  }

  &__floor-img {

  }

  &__include-title {
    margin-bottom: 39px;
  }

  &__includes {
    padding-top: 80px;
    padding-bottom: 80px;

    table {
      width: 100%;
    }
  }

  &__includes-data {

  }

  &__about {
    background-color: $bg-color;
    padding: 75px 0 35px;
  }

  &__about-title {
    margin-bottom: 40px;
  }

  &__about-container {
    display: flex;
  }

  &__about-text {

  }

  &__about-form {
    flex: 0 0 440px;
    width: 440px;
    margin-left: 68px;
    background-color: #fff;
    padding: 80px 40px 80px 50px;
    align-self: flex-start;
  }

  &__about-form-title {
    font-size: 36px;
    font-weight: 500;
    color: $text-color;
    margin-bottom: 30px;
    line-height: 1;
  }

  &__about-form-subtitle {
    color: $text-color;
    margin-bottom: 20px;
  }

  &__similar-title {
    margin-bottom: 40px;
  }

  &__similar {
    background-color: $bg-color;
    padding: 80px 0 100px;
  }

  &__similar-list {

  }

  &__similar-form {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0 80px;
  }

  &__similar-form-container {
    flex: 0 0 100%;
    padding: 40px 0;
  }

  &__similar-form-title {
    font-size: 36px;
    font-weight: 500;
    color: $text-color;
    margin-bottom: 30px;
  }

  &__similar-form-subtitle {
    color: $text-color;
    margin-bottom: 15px;
  }
}

@include respond('notebook') {
  .project {
    &__images-form {
      width: 730px;
      flex: 0 0 730px;
    }
  }
}

@include respond('medium') {
  .project {
    &__main {
      margin-bottom: 40px;
    }

    &__images-form {
      width: calc(100% - 300px);
      flex: 0 0 calc(100% - 300px);
    }

    &__badge {
      top: 10px;
      right: 19px;
    }

    &__form {
      border: 20px solid #F6F7F7;
      padding: 40px 37px 43px;
      margin-bottom: 50px;

      input[type=text], button {
        height: 40px;
        font-size: 13px;
      }

      .recall__field_button {
        font-size: 14px;
        min-width: 160px;
      }
    }

    &__form-title {
      font-size: 22px;
      margin-bottom: 10px;
    }

    &__form-description {
      font-size: 14px;
      margin-bottom: 10px;
    }

    &__options {
      padding-left: 30px;
      flex: 0 0 300px;
    }

    &__price {
      margin-bottom: 20px
    }

    &__price-value {
      font-size: 24px;
    }

    &__price-description {
      font-size: 13px;
    }

    &__order {
      margin-bottom: 24px;
    }

    &__request-button {
      font-size: 15px;
      text-align: center;
      height: 45px;
      line-height: 45px;
    }

    &__dimensions {
      margin-bottom: 40px;
    }

    &__dimension {
      padding: 10px 15px;
      font-size: 13px;
    }

    &__dimension-icon {
      flex: 0 0 28px;
      width: 28px;
      align-items: center;
      padding-bottom: 2px;
    }

    &__dimension-value {
      flex: 0 0 50px;
      width: 50px;
    }

    &__characteristic-icon {
      width: 38px;
      flex: 0 0 38px;

      svg {
        width: 28px;
        height: 28px;
      }
    }

    &__characteristic {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__characteristic-info {
      flex: 1 1 auto;
    }

    &__characteristic-name {
      font-size: 14px;
      margin-bottom: 3px;
    }

    &__characteristic-value {
      font-size: 13px;
    }

    &__subtitle {
      font-size: 30px;
    }

    &__floors {
      margin-bottom: 60px;
    }

    &__floors-title {
      margin-bottom: 30px;
    }

    &__floors-items {
      display: flex;
      margin: 0 -15px;
    }

    &__floor {
      flex: 0 0 50%;
      width: 50%;
      padding: 0 15px;
    }

    &__floor-title {
      font-size: 18px;
    }

    &__about {
      background-color: $bg-color;
      padding: 45px 0 35px;
    }

    &__about-title {
      margin-bottom: 30px;
    }

    &__about-form {
      flex: 0 0 300px;
      width: 300px;
      margin-left: 38px;
      padding: 40px 30px 40px 30px;
    }

    &__about-form-title {
      font-size: 28px;
      margin-bottom: 20px;
    }

    &__about-form-subtitle {
      margin-bottom: 15px;
    }

    &__include-title {
      margin-bottom: 28px;
    }

    &__includes {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &__similar-title {
      margin-bottom: 40px;
    }

    &__similar {
      background-color: $bg-color;
      padding: 40px 0 40px;
    }

    &__similar-list {

    }

    &__similar-form {
      padding: 0 50px;
    }

    &__similar-form-container {
      padding: 20px 0;
    }

    &__similar-form-title {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    &__similar-form-subtitle {
      margin-bottom: 15px;
    }
  }
}

@include respond-down('small') {
  .project {
    &__main {
      display: block;
    }

    &__images-form {
      width: 100%;
    }

    &__badge {
      top: 10px;
      right: 10px;
    }

    &__form {
      border: 10px solid #F6F7F7;
      padding: 20px;
      margin-bottom: 20px;
      display: none;
    }

    &__form-title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    &__form-description {
      font-size: 14px;
      margin-bottom: 12px;
    }

    &__options {
      padding: 20px 0 40px;
      width: 100%;
    }

    &__price {
      margin-bottom: 20px
    }

    &__price-value {
      font-size: 24px;
    }

    &__price-description {
      font-size: 13px;
    }

    &__order {
      margin-bottom: 24px;
    }

    &__request-button {
      font-size: 15px;
      text-align: center;
      height: 45px;
      line-height: 45px;
    }

    &__dimensions {
      margin-bottom: 40px;
    }

    &__dimension {
      padding: 10px 15px;
      font-size: 13px;
    }

    &__dimension-icon {
      flex: 0 0 28px;
      width: 28px;
      align-items: center;
      padding-bottom: 2px;
    }

    &__dimension-value {
      flex: 0 0 50px;
      width: 50px;
    }

    &__characteristic-icon {
      width: 38px;
      flex: 0 0 38px;

      svg {
        width: 28px;
        height: 28px;
      }
    }

    &__characteristic {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__characteristic-info {
      flex: 1 1 auto;
    }

    &__characteristic-name {
      font-size: 14px;
      margin-bottom: 3px;
    }

    &__characteristic-value {
      font-size: 13px;
    }

    &__subtitle {
      font-size: 22px;
    }

    &__floors {
      margin-bottom: 30px;
    }

    &__subtitle {
      font-size: 22px;
    }

    &__floors-title {
      margin-bottom: 16px;
    }

    &__floors-items {
      display: block;
      margin: 0;
    }

    &__floor {
      padding: 0;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__floor-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    &__floor-image {

    }

    &__floor-img {

    }

    &__about {
      background-color: $bg-color;
      padding: 30px 0 15px;
    }

    &__about-container {
      display: block;
    }

    &__about-title {
      margin-bottom: 15px;
    }

    &__about-form {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
      padding: 30px 25px 30px 25px;
    }

    &__about-form-title {
      font-size: 22px;
      margin-bottom: 10px;
    }

    &__about-form-subtitle {
      font-size: 14px;
      margin-bottom: 15px;
    }

    &__include-title {
      margin-bottom: 16px;
    }

    &__includes {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &__similar-title {
      margin-bottom: 40px;
    }

    &__similar {
      background-color: $bg-color;
      padding: 40px 0 5px;
    }

    &__similar-list {

    }

    &__similar-form {
      padding: 0 20px;
    }

    &__similar-form-container {
      padding: 25px 0;
    }

    &__similar-form-title {
      font-size: 22px;
      margin-bottom: 10px;
    }

    &__similar-form-subtitle {
      margin-bottom: 15px;
      font-size: 14px;
    }
  }
}
