.article-card {
  display: block;
  background-color: #fff;
  height: 100%;

  &__image {
    display: block;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    display: block;
    height: 250px;
  }

  &__info {
    display: block;
    padding: 38px 40px;
  }

  &__date {
    display: block;
    color: #3A3A3A;
    font-size: 13px;
    margin-bottom: 10px;
  }

  &__name {
    display: block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  &__more {
    color: $primary-color;
    border-bottom: 1px solid $primary-color;
  }
}

@include respond('medium') {
  .article-card {
    &__info {
      display: block;
      padding: 20px 20px;
    }

    &__date {
      font-size: 11px;
      margin-bottom: 5px;
    }

    &__name {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &__more {
      font-size: 13px;
    }

    &__img {
      height: 180px;
    }
  }
}

@include respond-down('small') {
  .article-card {
    &__info {
      display: block;
      padding: 20px 20px;
    }

    &__date {
      font-size: 11px;
      margin-bottom: 5px;
    }

    &__name {
      font-size: 16px;
      margin-bottom: 7px;
    }

    &__more {
      font-size: 13px;
    }

    &__img {
      height: 200px;
    }
  }
}