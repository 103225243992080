.work-card {
  display: block;
  position: relative;

  &__image {
    display: block;
    overflow: hidden;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    display: block;
  }

  &__badge {
    top: 0;
    right: 0;
  }

  &__info {
    display: block;
    background-color: #fff;
  }

  &__data {

  }

  &__title {
    font-weight: 500;
    color: #363333;
    display: block;
    line-height: 1;
  }

  &__dimensions {
    display: flex;
    align-items: center;
    margin: 0 -9px;
  }

  &__dimension {
    padding: 0 9px;
    display: flex;
    align-items: center;
  }

  &__dimension-icon {
    margin-right: 9px;
    padding-bottom: 2px;
  }

  &__dimension-value {
    font-size: 15px;
    color: #363333;
  }

  &__description {
    display: block;
  }

  &__description-item {

  }

  &__price {
    display: block;
  }

  &__price-description {

  }

  &__price-value {

  }

  &__price-numbers {
    font-weight: 500;
  }

  &__term {

  }

  &__term-value {
    font-weight: 500;
  }

  &__button {
    background-color: $secondary-color;
    font-size: 17px;
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    text-align: center;
    bottom: -30px;
    z-index: 120;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0,30px,0);
    transition: all 0.3s;
  }

  &:hover {
    .work-card {
      &__button {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0,0,0);
        transition: all 0.6s;
      }
    }
  }

  &_big {
    .work-card {
      &__img {
        height: 380px;
      }

      &__info {
        padding: 50px 40px;
        border: 30px solid #F6F7F7;
        border-top: none;
      }

      &__title {
        font-size: 30px;
        margin-bottom: 15px;
      }

      &__dimensions {
        margin-bottom: 20px;
      }

      &__price-numbers {
        font-size: 20px;
      }

      &__price-description {
        display: block;
        font-size: 15px;
        color: #363333;
        margin-bottom: 4px;
      }

      &__term {
        display: block;
        margin-top: 10px;
        font-size: 15px;
      }

      &__description {
        display: none;
      }
    }
  }

  &_line {
    display: flex;

    .work-card {
      &__info {
        order: 1;
        flex: 1 1 auto;
        display: flex;
        align-items: center;
      }

      &__image {
        order: 2;
        flex: 0 0 54.2735%;
      }

      &__img {
        height: 430px;
      }

      &__info {
        padding: 50px 40px;

        border: 30px solid #F6F7F7;
        border-right: none;
      }

      &__title {
        font-size: 30px;
        margin-bottom: 15px;
      }

      &__dimensions {
        margin-bottom: 15px;
      }

      &__price-numbers {
        font-size: 20px;
      }

      &__price-description {
        font-size: 15px;
        color: #363333;
        margin-bottom: 4px;
      }

      &__description {
        color: #363333;
        font-size: 15px;
        margin-bottom: 20px;
      }

      &__description-item {
        display: block;
      }

      &__button {
        width: 300px;
        left: 100px;
      }
    }
  }
}

@include respond('medium') {
  .work-card {
    &__dimensions {
      margin: 0 -7px;
    }

    &__dimension {
      padding: 0 7px;
      display: flex;
      align-items: center;
    }

    &__dimension-icon {
      margin-right: 5px;
      padding-bottom: 2px;
    }

    &__dimension-value {
      font-size: 13px;
    }

    &__button {
      height: 45px;
      line-height: 45px;
      bottom: -10px;
      font-size: 14px;
    }

    &_big {
      .work-card {
        &__img {
          height: 270px;
        }

        &__title {
          font-size: 24px;
        }

        &__info {
          padding: 30px 30px;
          border: 20px solid #F6F7F7;
          border-top: none;
        }

        &__term {
          font-size: 14px;
        }
      }
    }

    &_line {
      .work-card {
        &__img {
          height: 330px;
        }

        &__title {
          font-size: 24px;
        }


        &__info {
          padding: 30px 30px;
          border: 20px solid #F6F7F7;
          border-right: none;
        }

        &__description {
          font-size: 14px;
        }

        &__term {
          font-size: 14px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .work-card {
    &_big, &_line {
      display: block;

      .work-card {
        &__img {
          height: 200px;
        }
        &__title {
          font-size: 20px;
        }

        &__description {
          display: none;
        }

        &__info {
          padding: 20px 20px;
          border: 10px solid #F6F7F7;
          border-top: none;
        }

        &__dimensions {
          margin: 0 -6px 10px;
        }

        &__dimension {
          padding: 0 6px;
          display: flex;
          align-items: center;
        }

        &__dimension-icon {
          margin-right: 5px;
          padding-bottom: 2px;
        }

        &__dimension-value {
          font-size: 13px;
          white-space: nowrap;
        }

        &__term {
          font-size: 15px;
        }
      }
    }
  }
}