.articles-list {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;

  &__item {
    flex: 0 0 33.3333%;
    padding: 0 15px 30px;
  }
}

@include respond('medium') {
  .articles-list {
    margin: 0 -10px;

    &__item {
      padding: 0 10px 20px;
    }
  }
}

@include respond-down('small') {
  .articles-list {
    margin: 0;
    display: block;

    &__item {
      padding: 0 0 10px;
    }
  }
}