$default-input-height: 50px;

button, input, textarea {
  font-family: $font;
}

form {
  label {
    font-size: 12px;
    color: #9f9f9f;
    margin-bottom: 2px;
    display: block;
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    color: $alert-color;
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';
  #{$inputs}, textarea, select {
    border: 1px solid #B5AEAF;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 0 10px;
    font-size: 14px;
    width: 100%;
    display: block;
  }

  #{$inputs}, select {
    height: $default-input-height;
  }

  textarea {
    padding: 5px 10px;
  }

}