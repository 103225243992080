.article-page {

  &__text {
    margin-bottom: 50px;
  }

  &__projects {
    padding: 60px 0 50px;
    background-color: $bg-color;
  }

  &__projects-list {

  }

  &__projects-title {
    font-weight: 500;
    font-size: 36px;
    margin-bottom: 35px;
  }
}

@include respond('medium') {
  .article-page {
    &__text {
      margin-bottom: 30px;
    }

    &__projects {
      padding: 40px 0 30px;
    }

    &__projects-title {
      font-size: 30px;
      margin-bottom: 28px;
    }
  }
}

@include respond-down('small') {
  .article-page {
    &__projects {
      padding: 20px 0 0;
    }

    &__projects-title {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
}