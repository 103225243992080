body {
  font-size: $body-font-size;
  font-family: $font;
  line-height: 1.3;
  color: #363333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.hidden {
    overflow: hidden;
  }
}