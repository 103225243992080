.pagination-list {
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    margin: 0 5px;

    a {
      padding: 5px 10px 4px;
      display: block;
      border: 1px solid $secondary-color;
      min-width: 35px;
      text-align: center;
      transition: all 0.3s;

      &:hover {
        color: #fff;
        background-color: $secondary-color;
      }
    }

    &.active {
      a {
        color: #fff;
        background-color: $secondary-color;
      }
    }
  }
}

@include respond-down('small') {
  .pagination-list {
    padding-top: 20px;

    li {
      margin: 0 3px 3px;

      a {
        padding: 3px 7px 2px;
        min-width: 28px;
      }
    }
  }
}