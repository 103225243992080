.hero {
  height: 800px;
  overflow: hidden;
  background: {
    position: 50% 100%;
    repeat: no-repeat;
    size: cover;
    image: url("../images/hero/house.jpg");
  };

  &__inner {
    position: relative;
  }

  &__wrapper-pin{
    position: absolute;
    top: 0;
    right: 177px;

    &:after{
      content: '';
      display: block;
      height: 350px;
      width: 2px;
      top: 0;
      left: 50%;
      position: absolute;
      background-color: $primary-text-color;
    }

    &:before{
      content: '';
      display: block;
      height: 7px;
      width: 7px;
      top: 351px;
      left: 50.3%;
      transform: translate(-50%);
      position: absolute;
      background-color: $primary-text-color;
    }
  }

  &__house-pin{
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background: $primary-color;
    overflow: hidden;
    padding: 12px 32px;

    &:after{
      left: -12px;
      top: -16px;
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      z-index: 2;
      background-color: $primary-text-color;
      transform: rotate(45deg);
    }
  }

  &__house-pin-text{
    color: white;
    font-family: $font;
    font-size: 17px;
    font-weight: 400;
  }

  &__form {
    position: relative;
    background-color: #fff;
    width: 540px;
    margin-top: 100px;
    padding: 85px 75px;
    margin-left: -75px;
  }

  &__bush {
    top: 80%;
    left: 45%;
    position: absolute;
    width: 462px;
    height: 263px;
    background: {
      position: 50% 50%;
      repeat: no-repeat;
      size: contain;
      image: url("../images/hero/bush.png");
    };
  }

  &__recall {
    padding-right: 80px;
  }

  &__title {
    font-size: 68px;
    font-weight: 500;
    line-height: 0.9;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 18px;
    margin-bottom: 44px;
    line-height: (30/18);
  }
}

@media (max-height: 700px) and (min-width: #{map-get(map-get($grid, large), from)}px)  {
  .hero {
    &__form {
      padding: 65px 55px;
      width: 450px;
      margin-left: 0;
    }

    &__title {
      font-size: 50px;
    }

    &__recall {
      padding-right: 0;
    }
  }
}

@include respond('notebook') {
  .hero {
    height: 600px;

    &__wrapper-pin{
      position: absolute;
      top: 60px;
      right: 220px;

      &:after{

        height: 250px;

      }

      &:before{
        top: 251px;
      }
    }

    &__form {
      padding: 65px 55px;
      width: 450px;
      margin-left: 0;
      margin-top: 40px;
    }

    &__title {
      font-size: 50px;
    }

    &__recall {
      padding-right: 0;
    }
  }
}

@include respond('medium') {
  .hero {
    height: 500px;

    &__form {
      padding: 35px 35px;
      width: 320px;
      margin-left: 0;
      margin-top: 40px;
    }

    &__title {
      font-size: 35px;
    }

    &__subtitle {
      font-size: 15px;
      margin-bottom: 25px;
    }

    &__recall {
      padding-right: 0;
    }

    &__bush {
      width: (462px/1.5);
      height: (263px/1.5);
    }
  }
}

@include respond-down('medium') {
  .hero {
    &__wrapper-pin{
      top: auto;
      bottom: 4%;
      right: 0;

      &:after, &:before {
        display: none;
      }
    }

    &__house-pin{
      &:after {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .hero {
    background: $bg-color;
    height: auto;

    &__wrapper-pin {
      display: none;
    }

    .row, .column {
      margin: 0;
      padding: 0;
    }

    &__image {
      height: 250px;
      background: {
        position: 70% 100%;
        repeat: no-repeat;
        size: cover;
        image: url("../images/hero/house.jpg");
      };
    }

    &__inner {
      position: relative;
      padding-bottom: 50px;
    }

    &__form {
      width: auto;
      margin: -50px 15px;
      padding: 25px;
    }

    &__title {
      font-size: 26px;
      margin-bottom: 12px;
    }

    &__subtitle {
      font-size: 14px;
      margin-bottom: 12px;
      line-height: 1.2;
    }

    &__recall {
      padding: 0;
    }

    &__bush {
      display: none;
    }
  }
}