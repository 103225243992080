.contacts {
  position: relative;

  &__container {
    position: relative;
    z-index: 100;
  }

  &__column {
    width: 570px;
  }

  &__info {
    border: 30px solid $bg-color;
    padding: 65px 60px;
    background-color: #fff;
  }

  &__breadcrumbs {
    margin-bottom: 15px;
  }

  &__title {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 28px;
  }

  &__items {

  }

  &__items-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__contact {
    display: flex;
  }

  &__contact-icon {
    width: 43px;
    display: block;
    flex: 0 0 43px;

    &_phone {
      svg {
        width: 21px;
        height: 21px;
      }
    }

    &_pm {
      svg {
        width: 21px;
        height: 29px;
      }
    }

    &_mail {
      svg {
        width: 24px;
        height: 19px;
      }
    }
  }

  &__contact-value {
    font-size: 18px;
  }

  &__form {
    border: 30px solid $bg-color;
    border-top: none;
    padding: 65px 50px;
    background-color: #fff;
  }

  &__form-subtitle {
    font-size: 18px;
    margin-bottom: 20px;
  }

  &__form-data {

  }

  &__map {
    background-color: #f1f1f1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

@include respond-down('medium') {
  .contacts {
    .row, .column {
      padding: 0;
      margin: 0;
    }

    &__column {
      width: 370px;
    }

    &__info {
      border: 20px solid $bg-color;
      padding: 40px 35px;
    }

    &__form {
      border: 20px solid $bg-color;
      border-top: none;
      padding: 40px 35px;
    }

    &__breadcrumbs {
      margin-bottom: 10px;
    }

    &__title {
      font-size: 28px;
      margin-bottom: 20px;
    }

    &__contact-icon {
      width: 30px;
      display: block;
      flex: 0 0 30px;

      &_phone {
        svg {
          width: 16px;
          height: 16px;
        }
      }

      &_pm {
        svg {
          width: 16px;
          height: 19px;
        }
      }

      &_mail {
        svg {
          width: 17px;
          height: 14px;
        }
      }
    }

    &__contact-value {
      font-size: 15px;
    }

    &__form-subtitle {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
}


@include respond-down('small') {
  .contacts {
    &__column {
      width: 100%;
    }

    &__map {
      display: block;
      position: static;
      height: 250px;
    }

    &__info {
      border: 10px solid $bg-color;
      padding: 20px 20px;
    }

    &__form {
      border: 10px solid $bg-color;
      border-top: none;
      padding: 20px 20px;
    }

    &__contact-value {
      font-size: 14px;
    }

    &__title {
      font-size: 24px;
      margin-bottom: 15px;
    }

    &__items-item {
      margin-bottom: 10px;
    }

    &__form-subtitle {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}