.about {
  &__services {
    grid-area: services;
    margin-bottom: 90px;
    width: 480px;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 40px;
    margin-bottom: 50px;
    line-height: 1.1;
  }

  &__services-title {

  }

  &__services-list {

  }

  &__services-list-item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__service {
    display: flex;
    align-items: center;
  }

  &__service-value {
    color: $primary-color;
    font-size: 44px;
    width: 70px;
    font-weight: 500;
    line-height: 1;
  }

  &__service-text {
    font-size: 18px;
    line-height: 1.2;
    color: $text-color;
  }

  &__why {
    grid-area: why;
    margin-bottom: 60px;
  }

  &__why-title {

  }

  &__whys-list {

  }

  &__why-item {

  }

  &__whys-list-item {
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__why-value {
    color: $secondary-color;
    font-size: 44px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 10px;
  }

  &__why-text {
    font-size: 18px;
    line-height: 1.2;
    color: $text-color;
  }

  &__description {
    grid-area: description;
    max-width: 540px;
    font-size: 18px;
    color: $text-color;
    margin-bottom: 100px;

    p, ul {
      font-size: 18px;
    }
  }

  &__reports {
    grid-area: reports;
    max-width: 570px;
    margin-right: 105px;

    &_news {
      grid-area: news;
    }
  }

  &__reports-title {

  }

  &__reports-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  &__reports-list-item {
    flex: 0 0 50%;
    padding: 0 15px 30px;
  }

  &__report {
    display: block;
  }

  &__report-img {
    display: block;
    height: 170px;
    object-fit: cover;
    width: 100%;
  }

  &__report-name {
    padding-top: 15px;
    font-size: 13px;
    color: $text-color;
    line-height: (18/13);
    display: block;
  }

  &__individual {
    grid-area: individual;
    padding: 30px;
    background-color: $bg-color;
    max-height: 570px;
  }

  &__individual-link {

  }

  &__individual-image {
    display: block;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
      transition: all 0.4s;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      background-image: url('../svg/play.svg');
      z-index: 10;
      width: 62px;
      height: 62px;
      margin-top: -31px;
      margin-left: -31px;
      transition: all 0.4s;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:hover {
      &:after {
        transform: scale(1.1);
      }

      &:before {
        background-color: rgba(0,0,0,0.4);
      }
    }
  }

  &__individual-img {
    display: block;
    object-fit: cover;
    height: 420px;
    width: 100%;
    margin-bottom: 34px;
  }

  &__individual-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__individual-text {
    font-size: 20px;
    line-height: 1;
    margin-right: 20px;
  }

  &__individual-button {
    height: 50px;
    line-height: 50px;
    width: 250px;
    flex: 0 0 250px;
    font-weight: 400;
  }

  &__advantages {
    grid-area: advantages;
    margin-bottom: 90px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;

    .slick-dots {
      position: absolute;
      bottom: -30px;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        margin: 0 5px;
        appearance: none;
        color: transparent;
        font-size: 0;
        width: 12px;
        height: 12px;
        background-color: #d6d6d6;
        border: none;
        border-radius: 100px;
        display: block;
        cursor: pointer;
        padding: 0;
      }

      .slick-active {
        button {
          background-color: $primary-color;
        }
      }
    }
  }

  &__advantages-item {
    padding: 0 10px;
  }

  &__advantages-block {
    background-color: #F6F7F7;
    padding: 46px 40px 56px;
    width: 510px;
  }

  &__advantages-image {
    margin-bottom: 20px;
  }

  &__advantages-name {
    font-size: 23px;
    color: #363333;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 1.2;
  }

  &__advantages-description {
    font-size: 18px;
  }

  &__awards {
    grid-area: awards;
    margin-bottom: 90px;
  }

  &__awards-title {

  }

  &__awards-list {
    display: flex;
    margin: 0 -15px 30px;
  }

  &__awards-list-item {
    flex: 0 0 33.3333%;
    padding: 0 15px;
  }

  &__award {
    display: block;
  }

  &__award-img {
    display: block;
  }

  &__awards-description {
    font-size: 18px;
  }

  &__latest-work {
    grid-area: latest;
    background-color: $bg-color;
    padding: 34px 27px 30px;
  }

  &__latest-work-title {
    font-weight: 500;
    font-size: 23px;
    margin-bottom: 25px;
  }

  &__latest-work-link {
    display: block;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
      transition: all 0.4s;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      background-image: url('../svg/play.svg');
      z-index: 10;
      width: 62px;
      height: 62px;
      margin-top: -31px;
      margin-left: -31px;
      transition: all 0.4s;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:hover {
      &:after {
        transform: scale(1.1);
      }

      &:before {
        background-color: rgba(0,0,0,0.4);
      }
    }
  }

  &__latest-work-img {
    display: block;
    height: 290px;
    object-fit: cover;
    width: 100%;
  }

  &_short {
    grid-template-areas:
            "services individual  individual"
            "why      individual  individual"
            "why      space           advantages"
            "description description advantages"
            "reports  reports     advantages"
            "reports  reports     latest"
            "reports  reports     space2";

    display: grid;
  }

  &_alone {
    grid-template-areas:
            "services individual  individual"
            "why      individual  individual"
            "why      space           advantages"
            "description description advantages"
            "reports  reports     reports"
            "awards  awards     awards";

    display: grid;

    .about {
      &__reports {
        margin: 0 0 50px;
        max-width: none;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__reports-list-item {
        flex: 0 0 25%;
        padding: 0 15px 30px;
      }
    }
  }
}

@include respond('medium') {
  .about {
    &_short {
      grid-template-areas:
              "services why"
              "description description"
              "individual individual"
              "advantages advantages"
              "reports reports"
              "latest latest";
      display: grid;
    }

    &_alone {
      grid-template-areas:
              "services why"
              "description description"
              "individual individual"
              "advantages advantages"
              "latest latest"
              "reports reports"
              "awards awards";
      display: grid;
    }

    &__services, &__why {
      width: 50%;
      min-width: 300px;
      margin: 0 0 30px;
    }

    &__services-list-item {
      margin-bottom: 20px;
    }

    &__services {
      width: auto;
      margin-right: 70px;
    }

    &__subtitle {
      font-size: 30px;
      margin-bottom: 30px;
    }

    &__service {
      display: block;
    }

    &__service-value {
      width: 65px;
      margin-bottom: 8px;
    }

    &__service-text {
    }

    &__why {
      margin-bottom: 30px;
    }

    &__whys-list-item {
      margin-bottom: 24px;
    }

    &__why-value {
      margin-bottom: 8px;
    }

    &__why-text {
    }

    &__description {
      margin-top: 20px;
      margin-bottom: 50px;
      max-width: none;
    }

    &__reports {
      margin: 0 0 20px;
      max-width: none;
    }

    &__reports-list-item {
      flex: 0 0 25%;
    }

    &_alone {
      .about {
        &__reports {
          margin-bottom: 30px;
        }

        &__reports-list {
          margin: 0 -8px;
        }

        &__reports-list-item {
          padding: 0 8px 16px;
          flex: 0 0 25%;
        }
      }
    }

    &__report-img {
      display: block;
      height: 120px;
    }

    &__report-name {
      padding-top: 10px;
      font-size: 11px;
    }

    &__individual {
      margin-bottom: 60px;
    }

    &__awards {
      margin-bottom: 60px;
    }

    &__advantages-block {
      width: calc(100vw - 40px);
    }
  }
}

@include respond-down('small') {
  .about {
    &_short {
      grid-template-areas:
              "services"
              "why"
              "description"
              "individual"
              "advantages"
              "reports"
              "latest";
      display: grid;
    }

    &_alone {
      grid-template-areas:
              "services"
              "why"
              "description"
              "individual"
              "advantages"
              "latest"
              "reports"
              "awards";
      display: grid;
    }

    &__services-list-item {
      margin-bottom: 20px;
    }

    &__services {
      width: auto;
      margin-bottom: 40px;
    }

    &__subtitle {
      font-size: 20px;
      margin-bottom: 20px;
    }

    &__service-value {
      font-size: 36px;
      width: 65px;
    }

    &__service-text {
      font-size: 16px;
      padding-bottom: 4px;
    }

    &__why {
      margin-bottom: 30px;
    }

    &__whys-list-item {
      margin-bottom: 24px;
    }

    &__why-value {
      font-size: 34px;
      margin-bottom: 8px;
    }

    &__why-text {
      font-size: 16px;
    }

    &__description {
      margin-bottom: 40px;

      p, ul {
        font-size: 14px;
      }
    }

    &__reports {
      margin: 0 0 20px;
    }

    &__reports-list {
      margin: 0 -8px;
    }

    &__reports-list-item {
      padding: 0 8px 16px;
      flex: 0 0 50%;
    }

    &_alone {
      .about {
        &__reports {
          margin-bottom: 20px;
        }

        &__reports-list {
          margin: 0 -8px;
        }

        &__reports-list-item {
          padding: 0 8px 16px;
          flex: 0 0 50%;
        }
      }
    }

    &__report-img {
      display: block;
      height: 120px;
    }

    &__report-name {
      padding-top: 10px;
      font-size: 11px;
    }

    &__individual {
      padding: 20px;
      margin-bottom: 30px;
    }

    &__individual-img {
      height: 200px;
      margin-bottom: 15px;
    }

    &__individual-container {
      display: block;
    }

    &__individual-button {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      width: 200px;
    }

    &__individual-text {
      font-size: 14px;
      margin: 0 0 10px;
      display: block;
    }

    &__awards {
      margin-bottom: 30px;
    }

    &__awards-list {
      margin: 0 -8px 16px;
    }

    &__awards-list-item {
      padding: 0 8px;
    }

    &__awards-description {
      font-size: 14px;
    }

    &__latest-work {
      padding: 20px;
    }

    &__latest-work-title {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &__latest-work-img {
      height: 200px;
    }

    &__advantages-block {
      width: calc(100vw - 30px);
    }

    &__advantages-block {
      background-color: #F6F7F7;
      padding: 24px 10px 56px;
    }

    &__advantages {
      margin-bottom: 30px;
    }

    &__advantages-image {
      margin-bottom: 20px;
    }

    &__advantages-name {
      font-size: 18px;
      color: #363333;
      font-weight: 500;
      margin-bottom: 10px;
      line-height: 1.2;
    }

    &__advantages-description {
      font-size: 14px;
    }
  }
}