.projects-block {
  background-color: $bg-color;
  padding: 110px 0;

  &__title {
    text-align: center;
    font-weight: 500;
    font-size: 44px;
    margin-bottom: 70px;
  }

  &__list {

  }

  &__advantages {
    display: flex;
    align-items: center;
  }

  &__advantages-container {
    height: 100%;
    flex: 0 0 100%;
    padding: 50px 95px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__advantage-title {
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 39px;
  }

  &__advantages-list {
    display: flex;
    width: 100%;
  }

  &__advantages-list-item {
    margin-right: 99px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__advantage-icon {
    margin-bottom: 15px;
  }

  &__advantage-text {
    font-size: 18px;
  }

  &__action {
    text-align: center;
    padding-top: 50px;
  }

  &__button {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    padding: 0 45px;
    font-weight: 400;
    font-size: 17px;
  }
}

@include respond-down('small') {
  .projects-block {
    padding: 40px 0;

    &__title {
      text-align: center;
      font-weight: 500;
      font-size: 28px;
      margin-bottom: 30px;
      line-height: 1.2;
    }

    &__advantages-container {
      display: block;
      padding: 20px;
    }

    &__advantage-title {
      font-size: 20px;
      margin-bottom: 15px;
    }

    &__advantages-list {
       display: block;
    }

    &__advantages-list-item {
      margin-right: 0;
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__advantage-icon {
      margin-bottom: 0;
      margin-right: 20px;
    }

    &__advantage-text {
      font-size: 15px;
    }

    &__action {
      padding-top: 10px;
    }

    &__button {
      height: auto;
      line-height: 1.2;
      padding: 10px 20px;
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .projects-block {
    padding: 50px 0;

    &__title {
      text-align: center;
      font-weight: 500;
      font-size: 35px;
      margin-bottom: 40px;
      line-height: 1.2;
    }

    &__advantages-container {
      padding: 20px 40px;
    }

    &__advantages-list-item {
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__advantages-list {
      justify-content: center;
    }

    &__advantage-icon {
      margin-bottom: 10px;
    }

    &__advantage-text {
      font-size: 14px;
    }

    &__advantage-title {
      font-size: 25px;
      margin-bottom: 20px;
      text-align: center;
    }

    &__action {
      padding-top: 15px;
    }

    &__item_form {

    }
  }
}

@include respond('notebook') {
  .projects-block {
    padding: 90px 0;

    &__title {
      text-align: center;
      font-weight: 500;
      font-size: 38px;
      margin-bottom: 50px;
      line-height: 1.2;
    }

    &__advantages-container {
      padding: 50px 75px;
    }

    &__advantages-list-item {
      margin-right: 70px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__action {
      padding-top: 30px;
    }
  }
}