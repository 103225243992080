.calc-index {
  background: #F6F7F7;

  &__title {
    text-align: center;
  }
}

@include respond-up('large') {
  .calc-index {
    padding: 70px 0 50px;

    &__title {
      //margin-bottom: -30px;
    }
  }
}


@include respond('medium') {
  .calc-index {
    padding: 70px 0 50px;

    &__title {
      //margin-bottom: -30px;
    }
  }
}

@include respond-down('small') {
  .calc-index {
    padding: 50px 0 30px;

    &__title {
      //margin-bottom: -40px;
      //font-size: 20px;
    }
  }
}