.step-input {
  margin-bottom: 46px;

  &:last-child {
    margin-bottom: 0;
  }

  &__name {
    font-weight: 500;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__values {
    display: grid;
    grid-gap: 18px;
  }

  &__value {
    input {
      display: none;
    }

    transition: all 0.4s;
    box-shadow: 0 0 0 0 rgba(142, 136, 137, 0.3);

    &:hover {
      transform: translateY(-6px);
      box-shadow: 0 10px 10px 0 rgba(142, 136, 137, 0.3);
    }
  }

  &__label {
    display: block;
    box-shadow: inset 0 0 0 1px #B5AEAF;
    height: 126px;
    padding: 19px 10px 0;
    text-align: center;
    font-size: 14px;
    line-height: 1.1;
    transition: all 0.4s;
    cursor: pointer;
  }

  input:checked + &__label {
    box-shadow: inset 0 0 0 2px $primary-color;
  }

  &__image {
    display: flex;
    justify-content: center;
    height: 48px;
    margin-bottom: 14px;
  }

  &__img {
    width: 45px;
    height: 48px;
    display: block;
  }

  &__inputs {
    display: grid;
    grid-gap: 18px;
    grid-template-columns: 170px 170px;
  }

  &__input-value {
    height: 35px;
    border: 1px solid #B5AEAF;
    padding: 0 10px;
    width: 100%;
  }

  &__input-label {
    display: block;
    margin-bottom: 4px;
  }
}

@include respond-up('medium') {
  .step-input {
    &__name {
      font-size: 18px;
      margin-bottom: 16px;
    }

    &__value {
      width: 170px;
    }

    &__values {
      grid-template-columns: repeat(auto-fill, 170px);
    }
  }
}

@include respond-down('small') {
  .step-input {
    &__name {
      font-size: 18px;
      margin-bottom: 16px;
    }

    &__values {
      grid-template-columns: 1fr 1fr;
    }

    &__label {
      font-size: 13px;
    }

    &__inputs {
      grid-template-columns: 1fr 1fr;
    }
  }
}