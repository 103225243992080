.header {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__top {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: #fff;
    z-index: 1000;
    box-shadow: inset 0 -1px 0 0 #edeeee;
  }

  ._sticky {
    .header {
      &__logo-link {
        height: 60px;

        img {
          height: 40px;
        }
      }

      &__menu-item-text_root {
        height: 60px;
      }

      &__menu-item {
        padding: 0 (32px/2);
      }

      &__socials {
        display: none;
      }

      &__phone {
        margin-bottom: 0;
      }

      &__recall {
        display: none;
      }
    }
  }

  &__logo {
    img {
      transition: all 0.4s;
      height: 40px;
    }
  }

  &__logo-link {
    height: 90px;
    display: flex;
    align-items: center;
    transition: all 0.4s;
  }

  &__menu {
    a {
      &:hover {
        color: $primary-color;
        transition: 0.1s;
        transition-delay: 0s;
      }
    }
  }

  &__menu-list {
    display: flex;
  }

  &__menu-item {
    padding: 0 (22px/2);

    &._has-children {
      &>.header__menu-link {
        .header__menu-item-text {
          &:after {
            margin-left: 5px;
            content: '';
            display: block;
            width: 10px;
            height: 6px;
            background: {
              image: url('../images/svg/down.svg');
              repeat: no-repeat;
            }
          }
        }
      }
    }
  }

  &__menu-link {

  }

  &__menu-item-text_root {
    height: 90px;
    display: flex;
    align-items: center;
    transition: all 0.4s;
  }

  &__actions {

  }

  &__socials {
    display: flex;
    align-items: flex-start;
  }

  &__social-link {
    margin-right: 10px;
    opacity: 0.7;
    transition: all 0.4s;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__phone {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  &__phone-icon {
    padding-right: 8px;
  }

  &__phone-link {
    font-size: 22px;
    font-weight: 500;
  }

  &__recall {
    text-align: right;
  }

  &__recall-link {
    border-bottom: 1px dashed;
  }

  &__mobile-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }

  &__mobile-wrapper {
    box-shadow: inset 0 -1px 0 0 $bg-color;
  }

  &__mobile-actions {
    display: flex;
    align-items: center;
  }

  &__mobile-logo {
    img {
      height: 36px;
      display: block;
    }
  }

  &__hamburger {
    height: 22px;
    margin-left: 20px;
    flex: 0 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 28px;
  }

  &__line {
    height: 3px;
    width: 100%;
    border-radius: 10px;
    background-color: $primary-color;
    transition: $default-transition;
  }
}

@include respond-up('medium') {
  .header {
    &__children {
      visibility: hidden;
      opacity: 0;
      transition: all 0.7s;
      pointer-events: none;

      position: absolute;
      width: 100%;
      max-width: 1170px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      padding: 28px 60px;
      z-index: 100;
      box-shadow: 0 0 14px -6px;

      .header {
        &__menu-item {

        }

        &__menu-item-text {
          height: auto;
        }
      }

    }

    &__children-men-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }

    &__menu-item:hover {

      &>a {
        color: $primary-color;
      }

      .header__children {
        visibility: visible;
        opacity: 1;
        transition: all 0.4s;
        pointer-events: auto;
      }
    }

    &__menu-item {
      &._has-children {

        &>.header__menu-link {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            width: 268px;
            height: 37px;
            transform: rotate(4deg);
            right: -200px;
            top: 80px;
            z-index: 10;
            display: none;
          }

          &:before {
            content: '';
            position: absolute;
            width: 268px;
            height: 37px;
            transform: rotate(-4deg);
            left: -200px;
            top: 80px;
            z-index: 10;
            display: none;
          }

          &:hover {
            &:after, &:before {
              display: block;
            }
          }
        }
      }
    }
  }
}

@include respond('notebook') {
  .header {
    &__phone {
      margin-bottom: 2px;
    }

    &__phone-icon {
      padding-right: 6px;

      .icon-phone {
        width: 20px;
        height: 20px;
      }
    }

    &__phone-link {
      font-size: 18px;
    }

    &__recall-link {
      font-size: 13px;
    }
  }
}

@include respond('medium') {
  .header {
    ._sticky {
      .header {
        &__logo-link img {
          height: 24px;
        }

        &__menu-item-text_root {
          height: 60px;
        }

        &__menu-item_root {
          padding: 0 (10px/2);
        }

        &__phone-link {
          font-size: 14px;
        }
      }
    }

    &__socials {

    }

    &__social-link_community {
      display: none;
    }

    &__logo-link, &__menu-item-text_root {
      height: 70px;
    }

    &__menu-item-text_root {
      height: 80px;
    }

    &__menu-link {
      font-size: 13px;
    }

    &__menu-item_root {
      padding: (14px/2);
    }

    &__logo-link {
      img {
        height: 26px;
      }
    }

    &__phone {
      margin-bottom: 0px;
    }

    &__phone-icon {
      padding-right: 6px;

      .icon-phone {
        width: 14px;
        height: 14px;
      }
    }

    &__phone-link {
      font-size: 13px;
    }

    &__recall {
      line-height: 1;
    }

    &__recall-link {
      font-size: 10px;
    }
  }
}

@include respond-down('small') {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1000;

    &__line {
      &:nth-child(2) {
        transition: all 0.6s;
      }

    }

    &.active {
      .header__mobile-menu {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }

      .header {
        &__line {

          &:nth-child(1) {
            transform-origin: 0 0;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
            transform: translate3d(-12px, 0px, 0);
            transition: $default-transition;
          }

          &:nth-child(3) {
            transform-origin: 0 0;
            transform: rotate(-45deg) translate3d(-2px, 0px, 0);
          }
        }
      }
    }

    &__mobile-menu {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      overflow: auto;
      transform: translateY(50px);
      transition: $default-transition;
      z-index: 200;
      opacity: 0;
      visibility: hidden;
      background-color: #fff;
      height: calc(100vh - 60px);
      padding: 10px 0 20px;
    }

    &__menu-list {
      display: block;
    }

    &__menu-item {
      padding: 0;
    }

    &__menu-link {
      display: block;
      padding: 5px 0;
    }

    &__menu-item-text {
      height: auto;
      position: relative;
      padding-left: 28px;
      display: block;

      &:before {
        content: "";
        width: 15px;
        left: 0;
        top: 7px;
        height: 3px;
        background-color: $primary-color;
        position: absolute;
      }

      &:after {
        width: 0;
        height: 0;
      }
    }

    &__menu-item._has-children > .header__menu-link .header__menu-item-text:after {
      display: none;
    }

    &__children {
      padding-left: 30px;
      display: none;
    }

    &__menu {
      margin-bottom: 25px;
    }

    &__contact {
      display: flex;
      margin-bottom: 14px;
    }

    &__contact-icon {
      width: 43px;
      display: block;
      flex: 0 0 43px;

      &_phone {
        svg {
          width: 21px;
          height: 21px;
        }
      }

      &_pm {
        svg {
          width: 21px;
          height: 29px;
        }
      }

      &_mail {
        svg {
          width: 24px;
          height: 19px;
        }
      }
    }

    &__contact-value {
      font-size: 14px;
    }

    &__social-link {
      margin-right: 15px;

      .icon-viber {
        width: 30px;
        height: 30px;
      }

      .icon-whatsapp {
        width: 27px;
        height: 30px;
      }
    }

    &__phone-link {
      padding-left: 5px;
    }
  }
}