.bill {
  &__image {
    object-fit: cover;
    width: 100%;
  }
}

@include respond-up('medium') {
  .bill {
    &__image_mobile {
      display: none;
    }
  }
}

@include respond-down('small') {
  .bill {
    &__image_desktop {
      display: none;
    }
  }
}